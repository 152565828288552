import { requestWithDualProtocol } from '@/apis/common'
import { AxiosResponse } from 'axios'

// 需要分包的用户账号
export const needApartPackUser = [
  '18071787648',
  '13684084716',
  '15184427348',
  '18512846336',
  '18215623476',
]

export function dealLblApartPackageCount(
  preData: any[],
  paibanData: any[],
  factory_id: string
) {
  return new Promise((resolve, reject) => {
    if (!factory_id) {
      resolve(false)
      return
    }
    // 拷贝 防止更改到源数据
    const preLayoutData = JSON.parse(JSON.stringify(preData))
    // 存储不同id
    const orderIds = new Set<string>()
    // 保存不同订单对应的板件
    const parts = new Map()
    preLayoutData.forEach((part: any) => {
      const { orderId } = part
      if (orderId) {
        if (parts.has(orderId)) {
          const target = parts.get(orderId)
          target.parts.push(part)
          target.count += part.amount
        } else {
          parts.set(orderId, { parts: [part], count: part.amount })
        }
        orderIds.add(part.orderId)
      }
    })

    if (!orderIds.size) {
      resolve(false)
      return
    }
    const requestArr: Promise<AxiosResponse<any>>[] = []
    // 开始请求接口
    orderIds.forEach((id) => {
      const target = parts.get(id)
      const p = requestLblApartPack(id, factory_id, target.count)
      requestArr.push(p)
    })
    Promise.all(requestArr)
      .then((resArr) => {
        const resultArr = resArr.map(({ data }) => data)
        // 所有请求返回值
        resultArr.forEach((request) => {
          const { status, data, message } = request
          if (status === 1) {
            reject(message)
            return
          }
          // 单个请求返回处理
          data.forEach((pack: any) => {
            const {
              box: { count, ggid },
              data,
            } = pack
            if (!ggid) return
            const target = parts.get(ggid)
            // 单个包对应的板件处理
            data.forEach((packPart: any) => {
              const { barcode } = packPart
              const t = target.parts.find(
                (it: any) => it.plankNum === barcode && !it.packageCount
              )
              if (!t) return
              // 添加包数数据信息
              t.packageCount = count
            })
          })
        })

        // 获取所有排版板件
        const paibanParts = paibanData.map((plank) => plank.parts).flat(1)
        // 为排版板件添加包数信息
        preLayoutData.forEach((part: any) => {
          const { partUniqueId } = part
          const target = paibanParts.find(
            (it) => it.partUniqueId === partUniqueId
          )
          target && (target.packageCount = part.packageCount)
        })
        resolve(paibanData)
      })
      .catch((err: any) => {
        throw new Error('出现错误,' + err)
      })
  })
}
function requestLblApartPack(
  orderId: string,
  factory_id: string,
  count: number
) {
  const baseUrl =
    process.env.VUE_APP_BASE_URL === 'https://test-api.eggrj.com/bluen'
      ? 'mensvsp-dev.thinkerx.com/'
      : 'mensvsp.thinkerx.com/'

  const url = `${baseUrl}api/ggui/prePackageList?factory_id=${factory_id}&ggid=${orderId}&part_count=${count}`
  return requestWithDualProtocol(url)
}
